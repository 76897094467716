<template>
	<div id="conatct">
		<headers :whiteFlg="true" :activeItem="3" />
		<div class="have-top-nav"></div>
		<div class="container">
			<div class="flex wrapper">
				<div class="plan plan-one" @click="ewmOnFlg = true">
					<div class="plan_title">
						<h3><i class="icon-swhz"></i>商务合作</h3>
					</div>
					<ul>
						<li>业务洽谈</li>
						<li>逻辑优化</li>
						<li>技术加持</li>
						<li>高效高质</li>
					</ul>
					<button class="btn">立即申请</button>
				</div>
				<div class="plan plan-two">
					<div class="plan_title">
						<h3><i class="icon-gwzp"></i>岗位招聘</h3>
					</div>
					<ul>
						<li v-for="(item,index) in recruitList" :key="index" @click="dialogClick(item,index)"><a
								href="javascript:;">{{item.recruitPost}}</a></li>
					</ul>
					<button class="btn"> <a href="mailto:xunzhongkeji2022@163.com" class="cff">投递邮件</a>
					</button>
					<p class="f14 c99">邮箱：xunzhongkeji2022@163.com</p>
				</div>
			</div>
		</div>
		<div :class="ewmOnFlg?'ewm-wrap on':'ewm-wrap'" @click="ewmOnFlg = false">
			<div class="blk">
				<img src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/officialWebsite/wxewm.jpg" alt="">
				<p>
					<span>企业微信</span><br>请打开微信扫一扫联系我们
				</p>
			</div>
		</div>
		<el-dialog title="岗位详情" :visible.sync="centerDialogVisible" width="50%" center append-to-body>
			<div ref="reportHTML" v-if="recruitList[currentInd]" v-html="recruitList[currentInd].recruitText "
				class="web-con"></div>
		</el-dialog>

		<el-dialog v-if="recruitList[currentInd]" :title="recruitList[currentInd].recruitPost"
			:visible.sync="formDialogVisible" width="50%" center append-to-body>
			kkk

		</el-dialog>
		<footers :bottom="true" />
	</div>
</template>

<script>
	const axios = require('axios');
	import headers from "./components/headers.vue";
	import footers from "./components/footers.vue";
	export default {
		name: "contact",
		components: {
			headers,
			footers
		},
		data() {
			return {
				ewmOnFlg: false,
				centerDialogVisible: false,
				formDialogVisible: false,
				recruitList: [],
				currentInd: 0,
				fileList: [],
				form: {
					name: '',
				},

				loading: false,
				date: new Date(),
				files: [],
				newAttachmentsTable: [],
				insert: true,
				noPersonalInformation: []
			}
		},
		mounted() {
			this.requestData()
		},
		methods: {
			requestData() {
				axios.post('/appOfficialWebsite/recruitList', {})
					.then((res) => {
						this.recruitList = res.data.data.recruitList;
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			dialogClick(item, ind) {
				this.currentInd = ind;
				this.centerDialogVisible = true;
			},
			formDialogClick() {
				this.formDialogVisible = true;
			},


		}
	}
</script>

<style scoped>
	.flex {
		display: flex;
		justify-content: space-around;
		padding: 5.5rem 0;
		align-items: stretch;
	}

	.plan {
		width: 22.2rem;
		border-radius: 0.8rem;
		background: #ffffff;
		box-shadow: 0.2rem 0.2rem 0.5rem #0000001f;
		text-align: center;
		position: relative;
		max-width: 48%;
		padding-bottom: 6rem;
	}

	.plan_title {
		width: 100%;
		height: 8.65rem;
		border-radius: 0.8rem 0.8rem 0px 0px;
		background: #1f4d0d;
	}

	.plan-two .plan_title {
		background: #ffa401;
	}

	.plan_title h3 {
		color: #ffffff;
		font-size: 1.7rem;
		padding: 3rem 0;
	}

	.plan_title h3 i {
		width: 2.35rem;
		height: 2.35rem;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: inline-block;
		margin-right: 0.5rem;
		vertical-align: middle;
	}

	.icon-swhz {
		background-image: url("../assets/swhz.png");
	}

	.icon-gwzp {
		background-image: url("../assets/gwzp.png");
	}

	.plan ul {
		padding: 1.5rem 0;
	}

	.plan li {
		padding: 1rem 0;
	}

	.plan li a {
		color: #333333;
		font-size: 1rem;
	}

	.plan .btn {
		position: absolute;
		bottom: 3rem;
		left: 50%;
		margin-left: -4.65rem;
		width: 9.3rem;
		text-align: center;
		font-size: 1.1rem;
		height: 2.75rem;
		border-radius: 0.4rem;
		background: #1f4d0d;
		color: #fff;
	}

	.plan p {
		position: absolute;
		bottom: 1.5rem;
		width: 100%;
		text-align: center;
		/* 	left: 50%; */
	}

	.plan button:hover {
		/* filter: brightness(1.3); */
		box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.35);
		text-shadow: 0.1rem 0.1rem 0.25rem rgba(0, 0, 0, 0.35);
		;
	}

	.plan button:hover a {
		color: #fff;
	}

	.plan-two .btn {
		background: #ffa401;
	}

	.ewm-wrap {
		position: fixed;
		opacity: 0;
		z-index: -999;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.8);
		transition: all .2s ease-in-out;
	}

	.ewm-wrap.on {
		opacity: 1;
		z-index: 999;
	}

	.ewm-wrap .blk {
		left: 50%;
		top: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
	}

	.ewm-wrap img {
		width: 10rem;
		height: 10rem;
		display: block;
	}

	.ewm-wrap p {
		text-align: center;
		color: #fff;
		font-size: 0.7rem;
		margin: 1rem 0;
	}

	/deep/ .el-dialog--center {
		border-radius: 10px !important;
	}

	.web-con ol {
		display: block;
		list-style-type: decimal;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	}

	/deep/ .web-con h1,
	/deep/ .web-con h2,
	/deep/ .web-con h3,
	/deep/ .web-con h4,
	/deep/ .web-con h5 {
		display: block;
		font-size: 1.17em;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		font-weight: bold;
	}

	/deep/ .web-con ol {
		display: block;
		list-style: decimal;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	}

	/deep/ .web-con li {
		display: list-item;
		text-align: -webkit-match-parent;
		list-style: decimal;
	}
</style>
